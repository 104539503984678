.createeventdetails {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: #0000008a;
    z-index: 22;
    }
  
    .detailspagecontainer {
      width: 100%;
      max-width: 84%;
      margin: 0 auto;
    }
  
    .createdivfrmmain {
      width: 100%;
      margin: 0 auto;
      background-color: #fff;
      padding: 30px 25px;
      border-radius: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 600px;
      min-width: 600px;
    }
  
    .trainingpageleftdivmain {
      padding-bottom: 10px;
    }
  
    .rsntxrpnewtxt {
      font-size: 19px;
      line-height: 26px;
      font-weight: 600;
      /* font-family: noirprolight; */
      color: #000;
    }
    .emailtxtpppptt{
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
      /* font-family: noirprolight; */
      color: #000;
      margin-bottom: 4px;
     }
     .emailtxtpppptt tr{
      padding-bottom: 7px;
     }
     .useemiailmnodaloadding{
      height: 100px;
      overflow-y: auto;
     }
    .typstrningwhselctnew {
      width: 100%;
      padding: 17px 15px;
      border-radius: 4px;
      margin: 10px 0px 10px 0px;
      border: none;
      box-shadow: 0px 4px 9px 0px #ccc;
    }

    .piechartsBox_area {
      width: 100%;
      height: auto;
      padding: 10px 10px;
      background-color: #fff;
      box-shadow: 0px 0px 5px 0px rgb(155 153 153 / 50%);
      border-radius: 12px;
      margin-bottom: 15px;
  }

  .cross_reg{
    cursor:pointer;
    padding-left: 27rem;
    /* padding-top: 2rem; */
    color: red;
  }